:root {
  --main-color : #06764e;
  --strong-color : #074d36;
  --light-color : #36d18d;

  font-family: "Noto Sans KR",sans-serif, Inter, system-ui, Avenir, Helvetica, Arial, ;
  line-height: 1.5;


--font-title : 58px;
--font-exttrem-2x : 34px;
--font-exttrem : 28px;
--font-large :20px;
--font-main : 16px;
--font-small : 16px;

  background: #ffffff;

  font-synthesis: none;
  text-rendering: optimizeLegibility;

}

html, body, ul, li, input{
  margin: 0;
  padding:0
}

input, select {
  border-radius: 6px;;
}

ul, li {
  text-decoration: none;
  list-style: none;
}

a{
  color : black;
  text-decoration: none;

}

/* header area */

.header-wrap{
  position: fixed;
  top:0;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.127);
  width: 100%;
  z-index: 10;
  background: #fff;
}

.header-mobile{
  position: sticky;
  top: 0;
  right: 0;
  left:0 ;
  bottom:0;
  transition: transform .3s ease-in-out;
  transform: translateX(0%);
  background: #fff;
  z-index: 100;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.127);
}


.header{
  width:auto;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  margin: 0 3rem;
}

.header-left .logo{
  vertical-align: bottom;
  width: 50px;
  height: auto;
  cursor: pointer;
  transition: transform .3s ease-in-out
}

.header-left .logo:hover{
  transform: scale(1.2)
}

.nav-menu{
  height: 100%;
}

.nav-menu{
  height: 63px;
}

.header ul{
display: flex;
justify-content: flex-end;
align-items: center;
gap:3rem;
height: 100%;
font-size: 18px;
font-weight: bold;
}



.header ul li{
  height:63px;


  }

.header ul li a{
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.header ul li:hover{
  color : var(--main-color)
}

.header ul li a:hover{
  color : var(--main-color)
}

.hamburger-icon {
  display: none; /* Added: Initially hide the hamburger icon */
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 24px;
  cursor: pointer;
}

.hamburger-line {
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease-in-out;
}

.hamburger-line.open:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger-line.open:nth-child(2) {
  opacity: 0;
}

.hamburger-line.open:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}







.main {
  margin: 0 auto;
  text-align: center;
  min-width: 320px;
  max-width: 900px;
  min-height: 100vh;

}



.search-background{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  min-height:40vh;
  background: var(--main-color);
}

.search-down-allow{
  width: 50px;
  margin-inline:auto ;
  animation: tagMotion .8s ease-in-out infinite alternate;

}


@keyframes tagMotion {
  0% {
    transform: translateY(0); /* Initial position */
  }
  100% {
    transform: translateY(10px); /* Final position */
  }
}
.search-wrap{
  min-width: 200px;
  max-width: 600px;
  width: 100%;
  margin-inline: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}
.search-wrap h1{
  padding-top: 100px;
  text-align: center;
}

.search-wrap >p:nth-of-type(2) > span{
  font-weight: 700;
}

.search-wrap >p:nth-last-child(1){
  font-size: 14px;
  color : #fff;
}

.search-wrap >p:nth-last-child(1) span{
  font-size: 14px;
  color : #424242;
}

.search-title {
  font-size: 1.5rem;
  line-height: 1.1;
  color : var(--strong-color);
  text-align: center;
  font-weight: 700;

  }


.form button[type=submit]:hover{
  background: var(--strong-color);
  color: #fff;
  font-weight: 700;
}



.notice{
  position: absolute;
  bottom: 8%;
  left:50%;
  transform: translate(-50%, -50%);
  font-size: 11px;
  color:#fff
}

h1{
  color : #fff
}

.search-wra h1>span{
  color : #000;
}

.search-wrap form{
  display: flex;
  gap: 1rem;

}

.search-select{
  vertical-align: bottom;
  width: 100%;
  height:40px;
  border: none;
  font-size: 20px;;

  padding-left: 10px;
  box-sizing: border-box;
}

.form input{
vertical-align: bottom;
height:40px;
border: none;
font-size: 20px;
flex: 2;
}

.search-DateRangePicker{
  /* display: grid; */
  grid-auto-flow: column;
  gap:34px;
}

form button[type=sumbit]:hover {
  background:var(--strong-color);
  color:#fff;
  flex: 1;
}

/* .search-button-wrap{
  gap: 2rem;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2fr 1fr;
} */

.search-DateRangePicker .react-datepicker-wrapper{
  display: block;
  width: auto;
}

.react-datepicker-wrapper input{
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
}


.react-datepicker__tab-loop{
  position: absolute;
}


button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6rem 3rem;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
  transition: border-color 0.25s;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

.content{
  display: grid;
  grid-template-columns: 1fr;
  margin-inline: auto;
  padding : 0 1rem;
  max-width :900px;
  min-width: 280px;
  box-sizing: border-box;
}


.book-wrap{
  display: grid;
  grid-template-columns: 1fr 2fr;
  border-bottom: 1px solid #cecece;
  padding : 3rem 1.5rem;
  /* padding-bottom : 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem; */
}

.book-wrap:hover{
  background: #f9f9f9;
}

.book-wrap img{
  display: block;
  max-width: 100%;
  min-width: 50px;
  border-radius: 8px;
  box-shadow: 0px 6px 16px #4a4a4a;
}


.book-wrap:nth-of-type(1){
  border-top: 1px solid #cecece;
  /* padding-top : 3rem; */
}

.book-brief{
  text-align: left;
  margin-left : 10px;
}

.book-brief .name{
  font-size:var(--font-large);
  font-weight: 600;
  margin-bottom: 20px;
}

.book-brief button{
  margin-top: 20px;
}

.book-brief button:hover{
background: var(--strong-color);
color : #fff;
font-weight: bold;
}


/* Book Detail page */

.detail-wrap{
  min-width: 300px;
  max-width: auto;
  display: grid;
  gap: 1rem;
  padding: 0 4rem;
  margin-top: 100px;
  box-sizing: border-box;
}

.detail-wrap .detail-book{
  display: flex;
  gap : 0.8rem;
  flex-wrap: nowrap;
  align-items: center;
}

.detail-wrap img{
  margin-right: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px #4a4a4a;
  width: fit-content;
  max-width: 300px;
}
@media screen and (max-width:580px) {
  .detail-wrap .detail-book{
    flex-direction: column;
  }
  .detail-wrap img{
    width: 100%;
  max-width: 300px;
  }
}



.detail-wrap .detail-name{
  font-size: 1.4rem;
  margin-bottom: 2rem;
  margin-top: 10px;
}

.detail-wrap .detail-info{
  text-align: left;
}

.detail-description {
  padding-bottom: 2rem;
  border-bottom: 1px solid #cecece;
}

.detail-description h3{
  margin: 0 0 0.8rem 0;
}


.detail-body{
  display: grid;
  grid-template-columns:  1fr, 1fr;
}

.detail-keyword-wrap{
  display: flex;
  flex-wrap: wrap;
  gap: .8rem;
  text-align: center;
  margin-top: 20px;
}

.detail-keyword{
  padding : .2rem .5rem;
  border: 1px solid var(--light-color);
  border-radius: 8px;
}

.detail-button{
  text-align: center;
  margin-bottom: 50px;
}








.local-title{
  margin-top: 100px;
  margin-bottom: 2rem;
}


.empty-map{
  width: 100%;
  height: 70dvh;
  background: var(--light-color);
  margin-bottom: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.local{
  margin-top :100px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;

}

.search-loader-wrap{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  width: 100%;
  height: 100%;
  min-width: 280px;
  min-height: 150px;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  border-radius: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
}

.search-loader-wrap >span{
  font-size: 12px;
}

.loader {
  /* border: 8px solid #f3f3f3; 
  border-top: 8px solid #000; */
  border-radius: 50%; 
  width: 42px; 
  height: 42px; 
  margin: 1rem 0;
  animation: spin 2s linear infinite; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  40%{ transform: rotate(270deg);}
  100% { transform: rotate(360deg); }
}